<div class="application-page full-width p-0">
  <nb-list class="container-fluid overflow-hidden" nbInfiniteList listenWindowScroll [threshold]="100" (bottomThreshold)="loadNext()">
    <nb-list-item class="row" *ngIf="role !== 'Sub Entity'">
      <div class="col-12">
        <ngx-workspaces-header (workspacesChanged)="updateWorkspaces($event)" (noMoreWorkspaces)="showNoMore($event)"> </ngx-workspaces-header>
      </div>
    </nb-list-item>
    <nb-list-item class="row py-2 mx-0" *ngIf="role !== 'Sub Entity'">
      <div class="col-12 col-md-6" *ngFor="let workspaceA of workspaces">
        <ngx-workspaces-details [workspace]="workspaceA" mode="regular"></ngx-workspaces-details>
      </div>
      <div class="col-12" *ngIf="noMoreWorkspaces">
        <h6 class="text-center py-4">You don’t have anymore workspaces.</h6>
      </div>
      <div class="col-12" *ngIf="workspaces?.length === 0">
        <h6 class="text-center">No workspaces found</h6>
      </div>
    </nb-list-item>
    <ng-template #NotSearch>
      <div class="row justify-content-md-center">
        <div class="col-xl-6 col-md-12" *ngFor="let workspace of workspaces">
          <ngx-workspaces-details [workspace]="workspace" mode="regular"> </ngx-workspaces-details>
        </div>
      </div>
    </ng-template>
    <nb-list-item class="row justify-content-md-center" *ngIf="role === 'Sub Entity'">
      <div class="col-xl-6 col-md-12">
        <ngx-workspaces-details [workspace]="workspace" mode="regular"> </ngx-workspaces-details>
      </div>
    </nb-list-item>
  </nb-list>
</div>
